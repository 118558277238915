import React from 'react';
import Stack, {type BoxProps} from '@mui/material/Box';
import type CSSType from 'csstype';

import {exhaustiveCheck} from 'modern-famly/util';

import {Text} from '../text';
import {type ListBreakpoint, useListMediaQuery} from './use-list-media-query';
import {ClassNames} from './shared';
import {Icon} from '../icon';

export type SortDirection = 'asc' | 'desc';

export type ListHeaderItemProps = {
    title?: string;
    textAlign?: CSSType.Property.TextAlign;

    /**
     * @default 'tabletPortrait'
     */
    displayFrom?: ListBreakpoint;

    px?: BoxProps['px'];

    /**
     * Adds a sorting indicator next to the title
     */
    sortDirection?: SortDirection;

    /**
     * Callback when the header item is clicked
     * Useful for toggling sorting direction
     */
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const ListHeaderItem = (props: ListHeaderItemProps) => {
    const {title, displayFrom = 'tabletPortrait'} = props;

    const isVisible = useListMediaQuery(displayFrom);

    return (
        <Stack
            className={ClassNames.ListHeader}
            display={isVisible ? 'inline-flex' : 'none'}
            px={props.px ?? 4}
            onClick={props.onClick}
            alignItems="center"
            flexDirection="row"
            justifyContent={props.textAlign === 'right' ? 'flex-end' : 'flex-start'}
            gap={2}
            sx={{
                cursor: props.onClick ? 'pointer' : 'default',
            }}
        >
            <Text variant="body-small" emphasized>
                {title}
            </Text>
            {props.sortDirection ? <SortIcon direction={props.sortDirection} /> : null}
        </Stack>
    );
};

const SortIcon = (props: {direction: SortDirection}) => {
    switch (props.direction) {
        case 'asc':
            return <Icon name="arrow_upward_alt" size={20} color="n400" />;
        case 'desc':
            return <Icon name="arrow_downward_alt" size={20} color="n400" />;
        default:
            exhaustiveCheck(props.direction);
            return null;
    }
};
