import React from 'react';
import styled from 'styled-components';
import {Icon, type Theme, Text, Stack, Button, Box} from 'modern-famly';
import i18next from 'i18next';

import {Base, Flex, type FlexProps} from 'web-app/react/components/layout/layout';
import {Body, type TextProps} from 'web-app/react/components/text/text';
import {s2, type SpacingDescription, s3} from 'web-app/styleguide/spacing';
import {hasValue} from 'web-app/util/typescript';
import {EventSize} from 'web-app/react/global-event/types';

import {type STATUS_TYPE} from './types';

interface InputProps {
    message: string;
    copyMessageControls: {
        shouldShowCopyErrorMessageButton: boolean;
        onCopyErrorMessage: VoidFunction;
        isMessageCopied: boolean;
    };
    expandMessageControls: {
        shouldShowExpandButton: boolean;
        onClick: VoidFunction;
    };
    onClose?: () => any;
    occurences?: number;
    className?: string;
    statusType: STATUS_TYPE;
    eventSize?: EventSize;
    e2eId?: string;
}

const AnimatedTextWrapper = styled.div`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    animation: fadeIn 0.5s ease;
`;

const getIconSize = (eventSize: EventSize | undefined) => {
    switch (eventSize) {
        case EventSize.Large:
            return 32;
        case EventSize.Default:
        default:
            return 24;
    }
};

const getIcon = (statusType: STATUS_TYPE, eventSize: EventSize | undefined) => {
    const iconSize = getIconSize(eventSize);

    switch (statusType) {
        case 'Success':
            return <Icon name="check" color="n0" size={iconSize} />;
        case 'Error':
            return <Icon name="warning" color="n0" size={iconSize} />;
        case 'Info':
        default:
            return <Icon name="info" color="n0" size={iconSize} />;
    }
};

const getBackgroundColor = (statusType: STATUS_TYPE, mfTheme: Theme) => {
    switch (statusType) {
        case 'Success':
            return mfTheme.colorPalette.g400;
        case 'Error':
            return mfTheme.colorPalette.r300;
        case 'Info':
        default:
            return mfTheme.colorPalette.b400;
    }
};

interface ContainerProps extends FlexProps {
    statusType: STATUS_TYPE;
}

const Container = styled(Flex)<ContainerProps>`
    background-color: ${props => getBackgroundColor(props.statusType, props.theme.mf)};
    position: relative;
    border-radius: 8px;
    box-shadow: ${props => props.theme.shadow.three};
    align-items: flex-start;
`;

const IconContainer = styled(Base)`
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    height: 24px;
`;

/**
 * Not using `pill` prop on purpose since we need a lot of pixel pushing to align the size for the current icons.
 * In the end we end up overriding all the pill specific css anyway.
 */
const StyledOccurrenceBadge = styled(Body)<{statusType: STATUS_TYPE} & TextProps>`
    display: inline-block;
    color: ${props => getBackgroundColor(props.statusType, props.theme.mf)};
    background: ${props => props.theme.mf.colorPalette.n0};
    text-align: center;
    border-radius: calc(19px / 2); // Smooth/circle-like rounded ends
    padding: 0 8px; // Together with a font-size of 14px this will give us a width of 24px with one character
    line-height: 19px; // Need odd amount of pixels to actually center text
    margin: 3px 0 2px 0; // Use margin to make the box size 24px like the icon
`;

const getContainerSpacing = (eventSize: EventSize | undefined): SpacingDescription => {
    switch (eventSize) {
        case EventSize.Large:
            return s3;
        case EventSize.Default:
        default:
            return s2;
    }
};

const NotificationBar: React.FC<React.PropsWithChildren<InputProps>> = props => {
    const {
        className,
        message,
        statusType,
        onClose,
        copyMessageControls,
        expandMessageControls,
        occurences,
        eventSize,
        e2eId,
    } = props;

    const messageElements = React.useMemo(() => {
        return message.split('\n').map((line, index) => (
            <Text key={index} color="n0" variant={eventSize === EventSize.Large ? 'h5' : 'body'}>
                {line}
            </Text>
        ));
    }, [message, eventSize]);

    return (
        <Container
            className={className}
            statusType={statusType}
            padding={getContainerSpacing(eventSize)}
            data-e2e-statustype={statusType}
            data-e2e-class="notification-bar"
        >
            <Flex align="flex-start" shrink={0} marginRight={s2}>
                <IconContainer>
                    {hasValue(occurences) && occurences > 1 ? (
                        <StyledOccurrenceBadge emphasized statusType={statusType}>
                            {occurences}
                        </StyledOccurrenceBadge>
                    ) : (
                        getIcon(statusType, eventSize)
                    )}
                </IconContainer>
            </Flex>
            <Stack direction="column" alignItems="start" data-e2e-id={e2eId} spacing={3} flex="1">
                <Stack direction="column" spacing={3} data-e2e-class="notification-bar-message">
                    {messageElements}
                </Stack>
                {expandMessageControls.shouldShowExpandButton && (
                    <Button
                        text={i18next.t('notificationBar.seeAll')}
                        icon="visibility"
                        variant="critical"
                        size="compact"
                        onClick={expandMessageControls.onClick}
                        data-e2e-class="notification-bar-see-all"
                    />
                )}
                {copyMessageControls.shouldShowCopyErrorMessageButton && (
                    <Stack alignItems="center" spacing={2} mb={2}>
                        <Button
                            text={i18next.t('notificationBar.copyDetails')}
                            icon="content_copy"
                            variant="critical"
                            size="compact"
                            onClick={copyMessageControls.onCopyErrorMessage}
                        />
                        {copyMessageControls.isMessageCopied && (
                            <AnimatedTextWrapper>
                                <Text variant="body" color="n0">
                                    {i18next.t('notificationBar.copied')}
                                </Text>
                            </AnimatedTextWrapper>
                        )}
                    </Stack>
                )}
            </Stack>
            {onClose ? (
                <Box cursor="pointer" onClick={onClose} display="inline-flex" data-e2e-class="notification-bar-close">
                    <Icon name="close" size={getIconSize(eventSize)} color="n0" />
                </Box>
            ) : null}
        </Container>
    );
};

export default NotificationBar;
