import React, {useState, useEffect, useRef} from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import styled, {css} from 'styled-components';

import {Base} from 'web-app/react/components/layout/layout';
import NotificationBar from 'web-app/react/components/notification-bar/notification-bar';
import {s0, s1} from 'web-app/styleguide/spacing';
import {media} from 'web-app/styleguide/utils';
import renderStyle from 'web-app/styleguide/render-style';
import {getTabBarHeight} from 'web-app/react/navigation-v2/helpers';

import {type Event, type GlobalEventState} from './helpers/create-reducer';
import {type StateProps, type EventSize} from './types';

interface ContainerProps {
    isShowingModals: StateProps['isShowingModals'];
    eventSize?: EventSize;
}

const Container = styled(Base)<ContainerProps>`
    position: fixed;
    right: 8px;
    z-index: 9999;
    width: 400px;

    ${media.notMobile`
        top: calc(60px + env(safe-area-inset-top));
        ${props =>
            props.isShowingModals
                ? css`
                      top: 4px;
                  `
                : ''}
    `}

    ${media.mobile`
        width: auto;
        left: 8px;
        bottom: calc(${props => getTabBarHeight(props.theme)}px + 15px + env(safe-area-inset-bottom));
    `}
`;

interface EventProps {
    event: Event;
    clearStatus: (eventId: string) => void;
    className?: string;
    eventSize?: EventSize;
}

const MAX_CHARACTER_LENGTH_BEFORE_TRUNCATE = 140;

const SingleEvent = ({event, className, eventSize, clearStatus}: EventProps) => {
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [isErrorMessageCopiedToClipboard, setIsErrorMessageCopiedToClipboard] = useState(false);
    const [hasExpandedMessage, setHasExpandedMessage] = useState(false);
    const [overrideSticky, setOverrideSticky] = useState(false);

    const shouldTruncate = event.message.length >= MAX_CHARACTER_LENGTH_BEFORE_TRUNCATE && event.statusType === 'Error';
    const shouldShowCopyErrorMessageButton = hasExpandedMessage && shouldTruncate;

    const truncateMessage = (message: string) => `${message.substring(0, MAX_CHARACTER_LENGTH_BEFORE_TRUNCATE)}...`;

    const message = shouldTruncate && !hasExpandedMessage ? truncateMessage(event.message) : event.message;

    const handleCopyErrorMessageToClipboard = () => {
        navigator.clipboard.writeText(event.message);
        setIsErrorMessageCopiedToClipboard(true);
    };

    useEffect(() => {
        const startTimeout = () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            timeoutIdRef.current = setTimeout(() => {
                clearStatus(event.id);
            }, event.duration);
        };

        if (!event.sticky) {
            startTimeout();
        }

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [event, clearStatus]);

    const handleClearStatus = () => {
        clearStatus(event.id);
    };

    const handleExpand = () => {
        setHasExpandedMessage(true);
        setOverrideSticky(true);
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
    };

    return (
        <NotificationBar
            copyMessageControls={{
                shouldShowCopyErrorMessageButton,
                onCopyErrorMessage: handleCopyErrorMessageToClipboard,
                isMessageCopied: isErrorMessageCopiedToClipboard,
            }}
            expandMessageControls={{
                shouldShowExpandButton: !hasExpandedMessage && shouldTruncate,
                onClick: handleExpand,
            }}
            className={className}
            message={message}
            occurences={event.occurences}
            statusType={event.statusType}
            onClose={event.sticky || overrideSticky ? handleClearStatus : undefined}
            eventSize={eventSize}
            e2eId={event.e2eId}
        />
    );
};

const GLOBAL_EVENT_ITEM_CLASS = 'global-event-item';

const AnimationStyling = renderStyle(css`
    .${GLOBAL_EVENT_ITEM_CLASS}-enter, .${GLOBAL_EVENT_ITEM_CLASS}-exit {
        transition: max-height 450ms cubic-bezier(0, 0, 0.58, 1), opacity 450ms cubic-bezier(0, 0, 0.58, 1);
    }

    .${GLOBAL_EVENT_ITEM_CLASS}-enter, .${GLOBAL_EVENT_ITEM_CLASS}-exit.${GLOBAL_EVENT_ITEM_CLASS}-exit-active {
        opacity: 0;
        max-height: 0;
    }
    .${GLOBAL_EVENT_ITEM_CLASS}-enter.${GLOBAL_EVENT_ITEM_CLASS}-enter-active, .${GLOBAL_EVENT_ITEM_CLASS}-exit {
        opacity: 1;
        max-height: 100vh;
    }
`);

interface GlobalEventProps {
    events: GlobalEventState['events'];
    clearStatus: (eventId: string) => void;
    isShowingModals: boolean;
    eventSize?: EventSize;
}

const GlobalEventContent: React.FC<React.PropsWithChildren<GlobalEventProps>> = props => {
    const {events, clearStatus, isShowingModals, eventSize} = props;

    return (
        <Container isShowingModals={isShowingModals}>
            <AnimationStyling />
            <TransitionGroup>
                {events.map(event => (
                    <CSSTransition timeout={600} classNames={GLOBAL_EVENT_ITEM_CLASS} key={event.id}>
                        <Base padding={[s1, s0]}>
                            <SingleEvent
                                className={GLOBAL_EVENT_ITEM_CLASS}
                                event={event}
                                clearStatus={clearStatus}
                                eventSize={eventSize}
                            />
                        </Base>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </Container>
    );
};

export default GlobalEventContent;
