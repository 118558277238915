import React from 'react';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import {type InputBaseComponentProps} from '@mui/material/InputBase';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';

import {Icon} from 'modern-famly/components/data-display';
import {useDataProps} from 'modern-famly/components/util';
import {exhaustiveCheck, hasValue} from 'modern-famly/util';
import {useModernFamlyContext} from 'modern-famly/system/modern-famly-provider';

import {type TextFieldProps} from '../text-field';

export const MIN_MAX_PROP_VIOLATION_ERROR =
    'NumericInput: The value of `min` cannot be larger than the value of `max`. Using 0 and Number.MAX_SAFE_INTEGER instead.';
export const NO_DECIMAL_STEP_ERROR =
    'NumericInput: `step` must be an integer when `allowDecimals` is false. Using 1 instead.';

export type NumericInputProps = {
    /**
     * ID attribute of the input element.
     */
    id?: string;

    /**
     * If `true`, the `input` element is focused during the first mount.
     */
    autoFocus?: TextFieldProps['autoFocus'];

    /**
     * If `true`, the component is disabled.
     * The prop defaults to the value (`false`) inherited from the parent FormControl component.
     */
    disabled?: TextFieldProps['disabled'];

    /**
     * The short hint displayed in the `input` before the user enters a value.
     */
    placeholder?: TextFieldProps['placeholder'];

    /**
     * The size of the component.
     */
    size?: TextFieldProps['size'];

    /**
     * If `true`, the `input` will take up the full width of its container.
     * @default false
     */
    fullWidth?: TextFieldProps['fullWidth'];

    /**
     * If `true`, the `input` will indicate an error.
     * The prop defaults to the value (`false`) inherited from the parent FormControl component.
     */
    error?: TextFieldProps['error'];

    /**
     * The change handler called when the component is updated.
     */
    onChange: (value?: number) => void;

    /**
     * Pass a ref to the `input` element.
     */
    inputRef?: React.Ref<typeof Input>;

    /**
     * The value that the input component holds
     */
    value: number | undefined;

    /**
     * The step by which the input changes its value (Defaults to 1)
     */
    step?: number;

    /**
     * The minimum value that the input can hold (Defaults to 0)
     */
    min?: number;

    /**
     * The maximum value that the input can hold (Defaults to NUMBER.MAX_SAFE_INTEGER)
     */
    max?: number;

    /**
     * If `true`, the input will allow decimal numbers (Defaults to false)
     */
    allowDecimals?: boolean;

    /**
     * If `true`, the input will display the increment&decrement buttons (Defaults to true)
     */
    showIncrementAndDecrementButtons?: boolean;
};

const roundToTwoDecimalPlaces = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

/**
 * There are a few cases where we need to have the input field show something,
 * but not propagate an `onChange` event. These edge cases will be used in the
 * `formatValue` function later on.
 */
type TypingEdgeCase =
    // Preparing to enter a negative number
    | {case: '-'}
    // Preparing to enter a negative decimal number
    | {case: '-0'}
    | {case: '-0.'}
    | {case: '-0.0'}
    // Preparing to enter a positive decimal number
    | {case: '*.'}
    | {case: '*.0'}
    // Even though the user is typing a number below the min value
    // or above the max value (when `max` is negative) we still must
    // show the number so the user can _eventually_ type a number
    // that's within the valid range. We don't want to propagate change
    // events in these scenarios though.
    | {case: 'below-min-value'; value: number}
    | {case: 'above-max-value'; value: number};

export const NumericInput = ({
    onChange: onChangeFromProps,
    id,
    value,
    autoFocus,
    disabled,
    placeholder,
    size,
    fullWidth,
    error,
    inputRef,
    step: stepFromProps = 1,
    min: minFromProps = 0,
    max: maxFromProps = Number.MAX_SAFE_INTEGER,
    allowDecimals = false,
    showIncrementAndDecrementButtons = true,
    ...props
}: NumericInputProps) => {
    const dataProps = useDataProps(props);

    const [typingEdgeCase, setTypingEdgeCase] = React.useState<TypingEdgeCase | undefined>(undefined);

    const {decimalSeparator} = useDecimalSeparator();

    // Only allow decimal steps when `allowDecimals` is true
    const step = React.useMemo(() => {
        if (!allowDecimals && stepFromProps % 1 !== 0) {
            console.error(NO_DECIMAL_STEP_ERROR);
            return 1;
        }

        if (allowDecimals) {
            return roundToTwoDecimalPlaces(stepFromProps);
        }

        return stepFromProps;
    }, [stepFromProps, allowDecimals]);

    // Validate that `min` and `max` makes sense
    const {min, max} = React.useMemo(() => {
        if (minFromProps > maxFromProps) {
            console.error(MIN_MAX_PROP_VIOLATION_ERROR);
            return {min: 0, max: Number.MAX_SAFE_INTEGER};
        }

        return {min: minFromProps, max: maxFromProps};
    }, [minFromProps, maxFromProps]);

    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTypingEdgeCase(undefined);
            // This happens when the input is cleared (e.g. by hitting backspace enough times)
            if (e.target.value === '') {
                onChangeFromProps(undefined);
                return;
            }

            // Ensure that dot is used as decimal separator - this is needed for
            // casting to a number later on
            const stringValue = e.target.value.replace(',', '.');

            // Ensure that user doesn't type in decimals if the prop doesn't allow it
            if (!allowDecimals && stringValue.includes('.')) {
                return;
            }

            // Handle cases where the user is preparing to enter negative numbers
            if (stringValue === '-' || stringValue === '-0' || stringValue === `-0.` || stringValue === `-0.0`) {
                // We only allow negative numbers if the min value is negative
                if (min >= 0) {
                    return;
                }

                setTypingEdgeCase({case: stringValue});
                return;
            }

            // We only allow typing a decimal separator if the current value is an integer
            if (stringValue.endsWith('.') && hasValue(value) && value % 1 === 0) {
                setTypingEdgeCase({case: '*.'});
                return;
            }

            // We allow typing a zero after a decimal separator
            if (stringValue.endsWith('.0')) {
                setTypingEdgeCase({case: '*.0'});
                // Note that we don't return here, because we still want
                // to update the value in the case where the user is deleting
                // the number last decimal
            }

            // Return if the value has more than two decimal places
            if ((stringValue.split('.').at(1) ?? []).length > 2) {
                return;
            }

            const newValue = Number(stringValue);

            if (isNaN(newValue)) {
                return;
            }

            if (min > 0 && newValue < min) {
                setTypingEdgeCase({case: 'below-min-value', value: newValue});
                return;
            }

            if (max < 0 && newValue > max) {
                setTypingEdgeCase({case: 'above-max-value', value: newValue});
                return;
            }

            // Ensure that the user doesn't type in an obnoxiously small or large number
            // which will lead to weird formatting (e.g. 1.23+24 or NaN)
            if (
                newValue >= Number.MAX_SAFE_INTEGER ||
                newValue <= Number.MIN_SAFE_INTEGER ||
                newValue < min ||
                newValue > max
            ) {
                return;
            }

            onChangeFromProps(roundToTwoDecimalPlaces(newValue));
        },
        [onChangeFromProps, value, min, max, allowDecimals],
    );

    const onBlur = React.useCallback(() => {
        setTypingEdgeCase(undefined);
    }, []);

    const increment = React.useCallback(() => {
        setTypingEdgeCase(undefined);
        const newCount = value ? value + step : step;

        if (newCount > max) {
            return;
        }

        if (newCount > Number.MAX_SAFE_INTEGER) {
            onChangeFromProps(Number.MAX_SAFE_INTEGER);
            return;
        }

        if (newCount < min) {
            onChangeFromProps(roundToTwoDecimalPlaces(min));
            return;
        }

        onChangeFromProps(roundToTwoDecimalPlaces(newCount));
    }, [onChangeFromProps, value, step, max, min]);

    const decrement = React.useCallback(() => {
        setTypingEdgeCase(undefined);
        const newCount = value ? value - step : -step;

        if (newCount < min) {
            return;
        }

        if (newCount < Number.MIN_SAFE_INTEGER) {
            onChangeFromProps(Number.MIN_SAFE_INTEGER);
            return;
        }

        if (newCount > max) {
            onChangeFromProps(roundToTwoDecimalPlaces(max));
            return;
        }

        onChangeFromProps(roundToTwoDecimalPlaces(newCount));
    }, [onChangeFromProps, value, step, min, max]);

    /**
     * Increment and decrement value when using up and down arrow keys
     */
    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!showIncrementAndDecrementButtons) {
                return;
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                increment();
            }

            if (e.key === 'ArrowDown') {
                e.preventDefault();
                decrement();
            }
        },
        [increment, decrement, showIncrementAndDecrementButtons],
    );

    // This will bring up the numpad keyboard on mobile devices
    const inputProps = React.useMemo<InputBaseComponentProps>(
        () => ({
            inputMode: allowDecimals ? 'decimal' : 'numeric',
            pattern: allowDecimals ? undefined : '[0-9]*',
        }),
        [allowDecimals],
    );

    // Format the value to show the user what they're typing
    // Note: order is important!
    const formattedValue = React.useMemo(() => {
        if (
            typingEdgeCase?.case === '-' ||
            typingEdgeCase?.case === '-0' ||
            typingEdgeCase?.case === '-0.' ||
            typingEdgeCase?.case === '-0.0'
        ) {
            return typingEdgeCase.case;
        }

        if (typingEdgeCase?.case === 'below-min-value') {
            return `${typingEdgeCase.value}`;
        }

        if (typingEdgeCase?.case === 'above-max-value') {
            return `${typingEdgeCase.value}`;
        }

        // We need to check for `undefined` at
        // this point in the flow to not accidentally render
        // "undefined.0" or similar in the input field
        if (value === undefined) {
            return '';
        }

        if (typingEdgeCase?.case === '*.') {
            return `${value}.`;
        }

        if (typingEdgeCase?.case === '*.0') {
            return `${value}.0`;
        }

        return `${value}`;
    }, [value, typingEdgeCase]);

    const startAdornment = React.useMemo(() => {
        if (!showIncrementAndDecrementButtons) {
            return null;
        }
        return (
            <ButtonAdornment
                disabled={disabled || (value ?? 0) <= min}
                size={size}
                as={Button}
                position="start"
                onClick={decrement}
            >
                <Icon name="remove" size={16} />
            </ButtonAdornment>
        );
    }, [showIncrementAndDecrementButtons, disabled, value, min, size, decrement]);

    const endAdornment = React.useMemo(() => {
        if (!showIncrementAndDecrementButtons) {
            return null;
        }
        return (
            <ButtonAdornment
                disabled={disabled || (value ?? 0) >= max}
                size={size}
                as={Button}
                position="end"
                onClick={increment}
            >
                <Icon name="add" size={16} />
            </ButtonAdornment>
        );
    }, [showIncrementAndDecrementButtons, disabled, value, max, size, increment]);

    return (
        <Input
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
            size={size}
            onKeyDown={onKeyDown}
            fullWidth={fullWidth}
            error={error}
            inputRef={inputRef}
            inputProps={inputProps}
            // Ensure to format the value with the correct decimal separator
            value={formattedValue.replace('.', decimalSeparator)}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            onChange={onChange}
            onBlur={onBlur}
            {...dataProps}
        />
    );
};

const Input = styled(MuiOutlinedInput)`
    &.MuiInputBase-adornedStart {
        padding-left: 0;
    }

    &.MuiInputBase-adornedEnd {
        padding-right: 0;
    }
    .MuiOutlinedInput-input {
        text-align: center;
    }
`;

const ButtonAdornment = styled(InputAdornment)<{
    size: NumericInputProps['size'];
    disabled: NumericInputProps['disabled'];
}>`
    align-items: center;
    align-self: stretch;
    background-color: ${props => props.theme.modernFamlyTheme.colorPalette.n75};
    border-bottom-left-radius: ${props => (props.position === 'start' ? '8px' : '0')};
    border-bottom-right-radius: ${props => (props.position === 'end' ? '8px' : '0')};
    border-color: transparent;
    border-top-left-radius: ${props => (props.position === 'start' ? '8px' : '0')};
    border-top-right-radius: ${props => (props.position === 'end' ? '8px' : '0')};
    color: ${props =>
        props.disabled
            ? props.theme.modernFamlyTheme.colorPalette.n200
            : props.theme.modernFamlyTheme.colorPalette.n500};
    display: flex;
    flex: 1 0 ${props => (props.size === 'compact' ? '32px' : '40px')};
    justify-content: center;
    max-height: ${props => (props.size === 'compact' ? '34px' : '46px')};
    min-width: unset;
    width: ${props => (props.size === 'compact' ? '32px' : '40px')};

    &:not(:disabled):hover {
        cursor: pointer;
        background-color: ${props => props.theme.modernFamlyTheme.colorPalette.n100};
    }
`;

const useDecimalSeparator = () => {
    const {locale} = useModernFamlyContext();

    const decimalSeparator = React.useMemo(() => {
        switch (locale) {
            case 'enGB':
            case 'enUS':
            case 'deCH':
                return '.';
            case 'daDK':
            case 'deDE':
            case 'nbNO':
            case 'esES':
            case 'esUS':
                return ',';
            default:
                exhaustiveCheck(locale);
                return '.';
        }
    }, [locale]);

    return {decimalSeparator};
};
