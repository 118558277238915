import React from 'react';
import Box from '@mui/material/Box';
// eslint-disable-next-line no-restricted-imports
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import {ResizeObserver} from '@juggle/resize-observer';

import {
    Button,
    type ButtonProps,
    DropdownButton,
    type DropdownButtonProps,
    type DropdownButtonOption,
} from 'modern-famly/components/input';
import {Text} from 'modern-famly/components/data-display/text';
import {useTranslation} from 'modern-famly/system/use-translation';
import {type DataProps, useDataProps, extractDataProps} from 'modern-famly/components/util';
import {useBreakpoints} from 'modern-famly/theming';
import {hasValue} from 'modern-famly/util';

import {BulkActionBarTestAttributes as TestAttributes} from './bulk-action-bar-test-attributes';

type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
type ButtonAction = DistributiveOmit<ButtonProps, 'size' | 'variant' | 'fullWidth'>;
type StickyAction = ButtonAction & {text: string};
type MoreAction = DistributiveOmit<DropdownButtonProps, 'size' | 'variant' | 'fullWidth'>;

export type BulkActionBarProps = {
    /**
     * Callback for when "deselect all button" is clicked
     */
    onDeselectAll: ButtonProps['onClick'];

    /**
     * Whether the bulk action bar is displayed
     */
    isOpen: boolean;

    /**
     * The number of selected items
     */
    selectedCount: number;

    /**
     * The primary action button's props
     */
    primaryAction: ButtonAction;

    /**
     * The secondary action button's props
     *
     * The 'text' property is required as it will be used in the more actions dropdown
     */
    secondaryAction?: StickyAction;

    /**
     * The tertiary action button's props
     *
     * The 'text' property is required as it will be used in the more actions dropdown
     */
    tertiaryAction?: StickyAction;

    /**
     * The more actions dropdown's props
     */
    moreActions?: MoreAction;

    /**
     * The element in which the bulk action bar should be positioned
     */
    anchorEl?: React.RefObject<HTMLElement> | null;
} & DataProps;

const useLocation = (anchorEl: BulkActionBarProps['anchorEl']) => {
    const [left, setLeft] = React.useState(0);

    React.useEffect(() => {
        const element = anchorEl?.current ?? document.body;

        const resizeObserver = new ResizeObserver(() => {
            const location = element.getBoundingClientRect();
            setLeft(location.left);
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver?.disconnect();
        };
    }, [anchorEl]);

    return left;
};

type ContentsProps = BulkActionBarProps & {
    selectedText: string;
    deselectAllText: string;
};

const transformStickyAction = (
    stickyAction: NonNullable<BulkActionBarProps['secondaryAction' | 'tertiaryAction']>,
): DropdownButtonOption | undefined => {
    const stickyActionButtonDataProps = extractDataProps(stickyAction);

    if (stickyAction.href) {
        return {
            href: stickyAction.href,
            onClick: stickyAction.onClick,
            label: stickyAction.text,
            disabled: stickyAction.isDisabled,
            ...stickyActionButtonDataProps,
        };
    }

    if (stickyAction.onClick) {
        return {
            onClick: stickyAction.onClick,
            label: stickyAction.text,
            disabled: stickyAction.isDisabled,
            ...stickyActionButtonDataProps,
        };
    }

    // Typescript doesn't know that the above if statements cover all cases
    // I cannot seem to convince it otherwise
    return undefined;
};

const DeselectButton = ({onClick, text}: {onClick: ButtonProps['onClick']; text: string}) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            sx={({modernFamlyTheme}) => ({
                // Reset browser default styles
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: '0',
                font: 'inherit',
                cursor: 'pointer',
                outline: 'inherit',

                // Custom styles
                textDecoration: 'underline',

                // A bit of focus styling for accessibility
                ':focus': {
                    outline: `1px solid ${modernFamlyTheme.colorPalette.n400}`,
                },
            })}
        >
            <Text variant="body" color="n400">
                {text}
            </Text>
        </Box>
    );
};

const MobileContents = ({
    onDeselectAll,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    moreActions,
    selectedText,
    deselectAllText,
}: ContentsProps) => {
    const collapsedOtherActions = React.useMemo((): BulkActionBarProps['moreActions'] => {
        if (!moreActions) {
            return moreActions;
        }
        const options = [
            ...moreActions.options,
            secondaryAction && transformStickyAction(secondaryAction),
            tertiaryAction && transformStickyAction(tertiaryAction),
        ].filter(hasValue);

        return {
            ...moreActions,
            options,
        };
    }, [moreActions, secondaryAction, tertiaryAction]);

    return (
        <Stack direction="column" gap={4}>
            <Stack direction="row" gap={3}>
                <Text variant="body">{selectedText}</Text>
                <DeselectButton onClick={onDeselectAll} text={deselectAllText} />
            </Stack>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: collapsedOtherActions ? '1fr 1fr' : '1fr',
                    gap: 3,
                }}
            >
                {collapsedOtherActions ? <DropdownButton {...collapsedOtherActions} variant="tertiary" /> : null}
                <Button {...primaryAction} variant="primary" />
            </Box>
        </Stack>
    );
};

const Contents = (props: ContentsProps) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={2}>
                <Text variant="body" color="n500">
                    {props.selectedText}
                </Text>
                <Button
                    variant="transparent"
                    onClick={props.onDeselectAll}
                    text={props.deselectAllText}
                    data-e2e-class={TestAttributes.deselectButton}
                />
            </Stack>
            <Stack direction="row" gap={2}>
                {props.moreActions ? (
                    <DropdownButton
                        {...props.moreActions}
                        variant="transparent"
                        size="regular"
                        data-e2e-class={TestAttributes.moreButton}
                    />
                ) : null}
                {props.tertiaryAction ? <Button {...props.tertiaryAction} variant="tertiary" size="regular" /> : null}
                {props.secondaryAction ? (
                    <Button {...props.secondaryAction} variant="secondary" size="regular" />
                ) : null}
                <Button {...props.primaryAction} variant="primary" size="regular" />
            </Stack>
        </Stack>
    );
};

export const BulkActionBar = (props: BulkActionBarProps) => {
    const selectedText = useTranslation('BulkActionBar.selectedCount', `${props.selectedCount}`);
    const deselectAllText = useTranslation('BulkActionBar.deselectAll');
    const dataProps = useDataProps(props);
    const left = useLocation(props.anchorEl);
    const {isTabletPortraitAndLarger} = useBreakpoints();

    return (
        <Popper
            open={props.isOpen}
            transition
            placement="bottom"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'fixed',
                bottom: {
                    base: 'calc(70px + env(safe-area-inset-bottom))',
                    tabletPortrait: '100px',
                },
                left: `${left}px !important;`,
                right: '0px',
                top: 'unset !important',
            }}
            disablePortal
        >
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={200}>
                    <Box
                        sx={theme => ({
                            backgroundColor: theme.modernFamlyTheme.colorPalette.n50,
                            borderRadius: 3,
                            border: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,
                            boxShadow: theme.modernFamlyTheme.elevation[5],
                            padding: {
                                base: 4,
                                tabletPortrait: 6,
                            },
                            maxWidth: {
                                base: '85%',
                                tabletPortrait: '80%',
                            },
                            flexGrow: '1',
                        })}
                        {...dataProps}
                    >
                        {isTabletPortraitAndLarger ? (
                            <Contents {...props} selectedText={selectedText} deselectAllText={deselectAllText} />
                        ) : (
                            <MobileContents {...props} selectedText={selectedText} deselectAllText={deselectAllText} />
                        )}
                    </Box>
                </Fade>
            )}
        </Popper>
    );
};
