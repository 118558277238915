import styled, {css} from 'styled-components';

export type ModalContentType = 'fullscreen';

interface ModalContentProps {
    type?: ModalContentType;
}
export const ModalContent = styled.div<ModalContentProps>`
    ${props => {
        const backgroundColor =
            props.type && props.type === 'fullscreen' ? props.theme.background : props.theme.invertedText;

        return css`
            background: ${backgroundColor};
        `;
    }}
`;
