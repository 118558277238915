import React from 'react';

import {StandaloneCheckbox, type StandaloneCheckboxProps} from 'modern-famly/components/input';
import {useDataProps} from 'modern-famly/components/util';

import {ListItem} from './list-item';

export type ListItemCheckboxProps = {
    isSelected: boolean;
    disabled?: StandaloneCheckboxProps['disabled'];
    onChange: StandaloneCheckboxProps['onChange'];
};

export const ListItemCheckbox = (props: ListItemCheckboxProps) => {
    const dataProps = useDataProps(props);
    return (
        <ListItem px={3} onClick={e => e.stopPropagation()}>
            <StandaloneCheckbox
                checked={props.isSelected}
                disabled={props.disabled}
                onChange={props.onChange}
                size="compact"
                {...dataProps}
            />
        </ListItem>
    );
};
