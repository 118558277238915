import {Record, List} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import generateUUID from 'web-app/util/uuid';
import {hasValue} from 'web-app/util/typescript';
import type {STATUS_TYPE} from 'web-app/react/components/notification-bar/types';

import {defaultDuration} from './constants';

export class Event extends Record<{
    id: string;
    sticky: boolean;
    message: string;
    statusType: STATUS_TYPE;
    occurences: number;
    duration: number;
    e2eId: string;
}>({
    sticky: false,
    id: '',
    message: '',
    statusType: 'Info',
    occurences: 1,
    duration: defaultDuration,
    e2eId: '',
}) {}

const eventsAreEqual = (a: Event, b: Event) => a.message === b.message && a.statusType === b.statusType;

export class GlobalEventState extends Record<{
    events: List<Event>;
}>({
    events: List<Event>(),
}) {}

const createGlobalEventReducer = globalEventActions => {
    return makeReducer<GlobalEventState>(
        [
            match(globalEventActions.updateStatus, (state, {payload}) => {
                const lastEvent = state.events.last();
                if (hasValue(lastEvent) && eventsAreEqual(lastEvent, payload)) {
                    return state.merge({
                        events: state.events.update(state.events.size - 1, event =>
                            event.merge({
                                ...payload,
                                sticky: event.sticky || payload.sticky,
                                occurences: event.occurences + 1,
                            }),
                        ),
                    });
                } else {
                    return state.merge({
                        events: state.events.push(
                            new Event({
                                ...payload,
                                id: generateUUID(),
                            }),
                        ),
                    });
                }
            }),
            match(globalEventActions.clearStatus, (state, {payload}) => {
                return state.merge({
                    events: state.events.filter(event => event.id !== payload.eventId),
                });
            }),
        ],
        new GlobalEventState(),
    );
};

export default createGlobalEventReducer;
